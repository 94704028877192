import React from 'react';
// Page Analytics related to Wix Pages
import {
  trackPageViewed,
  analyticsDataRegistry,
  getTrackingConfiguration,
  TrackingConfiguration,
  trackProductPageLoad,
  trackProductViewed,
  trackProductListViewed,
  PageAnalyticsData,
  TrackingAction,
  TrackingPageSection,
  TrackingPageLocation,
  trackToggleEvent,
  trackPopUpEvent,
  trackProductAdded,
  trackProductListViewedV2,
  trackPlansAdded,
  trackPlanChosenEvent,
  TrackingEventDetail,
  TrackingListIds,
} from '@vp/digital-tracking-lib';
import { invariant } from '@vp/digital-invariant-lib';
import { wixErrorTypeEnum } from '../components/common/wixErrorPage/wixErrorTypeEnum';
import { experimentKeyEnum, variationKeyEnum } from '../enums/experimentKey';
import {
  fireImpression,
  activate,
  initialize,
  waitTillAvailable,
} from '@vp/ab-reader';
const BILLING_PAGE_LABEL = 'Vista Wix Billing Cycle page view';
const PLANS_PAGE_LABEL = 'Vista Wix Plan Offer page view';

const ANONYMOUS_FLOW = 'Anonymous Flow';
const INITIAL_UPGRADE_FLOW = 'Initial Upgrade Flow';
const CHANGE_PLAN_FLOW = 'Change Plan Flow';
const SECOND_UPGRADE_FLOW = 'Second Upgrade Flow';

const INITIAL_PLANS_PAGE_LABEL = 'Vista Wix Plan Initial Upgrade page view';
const CHANGE_PLANS_PAGE_LABEL = 'Vista Wix Plan Change page view';
const MODAL_CONFIRMATION_POPUP_LABEL = 'Modal Confirmation Pop-Up';
const SECOND_PLANS_PAGE_LABEL = 'Vista Wix Plan Upgrade page view';
const SITE_SELECTION_PAGE_LABEL =
  'Vista Wix Plan Upgrade - Site Selection page view';
const PLANS_PAGE_WEBSITES_VARIANT = [
  'plan1:VIP',
  'plan2:Pro',
  'plan3:Unlimited',
  'plan4:Combo',
];
const PLANS_PAGE_BUSINESS_VARIANT = [
  'plan1:VIP',
  'plan2:Unlimited',
  'plan3:Basic',
];
const PLANS_PAGE_VARIANT = [
  'plan1:Light',
  'plan2:Core',
  'plan3:Business',
  'plan4:Business Elite',
];
const PLANS_PAGE_VARIANT_WITH_FREE = [
  'plan1:Free',
  'plan2:Light',
  'plan3:Core',
  'plan4:Business',
  'plan5:Business Elite',
];
const PLANS_LIST_COUNT_WITH_FREE = 5;

const WEBSITES_PLANS_LIST_COUNT = 4;
const BUSINESS_PLANS_LIST_COUNT = 3;
const PLANS_LIST_COUNT = 4;
const LIST_SECTION_ID = 'Website Plans Picker';
interface WixPageAnalyticsProviderModel {
  trackProductLoad(): void;
  trackPageLoad(): void;
  trackErrorPageLoad(ErrorType: wixErrorTypeEnum): void;
  trackInitialBillingPageLoad(planSelected: string): void;
  trackPlansBusinessPageLoad(planPageType: PageAnalyticsData): void;
  trackPlansWebsitesPageLoad(planPageType: PageAnalyticsData): void;
  trackPlansPageLoad(planPageType: PageAnalyticsData, locale?: string): void;
  trackCancelPageStep1PageLoad(): void;
  trackWebsShutdownPageStep1PageLoad(): void;
  trackSiteSelectorPageLoad(planSelected: string): void;
  trackPopUpViewedEvent(page: PageAnalyticsData, Event?: string): void;
  trackPopUpClosedEvent(page: PageAnalyticsData, Event?: string): void;
  trackPopUpClickedEvent(page: PageAnalyticsData, Event?: string): void;
  trackModalViewedEvent(page: PageAnalyticsData, Event?: string): void;
  trackModalClosedEvent(page: PageAnalyticsData, Event?: string): void;
  trackModalClickedEvent(page: PageAnalyticsData, Event?: string): void;
  trackToggleClickedEvent(page: PageAnalyticsData, Event?: string): void;
  trackPlanChosen(
    page: PageAnalyticsData,
    Event?: string,
    isUpgradeMonthlyToYearly?: boolean
  ): void;
  trackPlansProductAdded(
    page: PageAnalyticsData,
    planName?: string,
    quantity?: number
  ): void;
  fireStatsigImpression(
    experimentKey: experimentKeyEnum,
    variationKey: variationKeyEnum
  ): void;
  trackingConfigData: TrackingConfiguration;
  trackingAssetName: string;
}

type WixPageAnalyticsProviderProps = {
  pageAnalyticsData: PageAnalyticsData;
  productKey: string;
  children: React.ReactNode;
};
export enum SubscriptionOptionsEnum {
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
}

const WixPageAnalyticsContext =
  React.createContext<WixPageAnalyticsProviderModel>(undefined);

const WixPageAnalyticsProvider = ({
  pageAnalyticsData,
  productKey,
  children,
}: WixPageAnalyticsProviderProps) => {
  const {
    wixOfacError,
    wixPlansWebsites,
    wixPlansBusiness,
    wixPlans,
    wixInitialPlansWebsites,
    wixInitialPlansBusiness,
    wixInitialPlans,
    wixChangePlans,
    wixUpgradePlansWebsites,
    wixUpgradePlansBusiness,
    wixUpgradePlans,
  } = analyticsDataRegistry;

  invariant(
    pageAnalyticsData,
    'WixPageAnalyticsProvider requires pageAnalyticsData'
  );

  const BaseConfigData = getTrackingConfiguration(pageAnalyticsData);

  const [trackingConfigData, setTrackingConfigData] =
    React.useState<TrackingConfiguration>(BaseConfigData);
  const [trackingAssetName, setTrackingAssetName] = React.useState<string>(
    pageAnalyticsData.trackingAssetName
  );

  function trackProductLoad(): void {
    trackProductPageLoad(pageAnalyticsData);
  }

  function trackPageLoad(pageData?: PageAnalyticsData): void {
    trackPageViewed(pageData ?? pageAnalyticsData);
  }

  function trackErrorPageLoad(ErrorType: wixErrorTypeEnum): void {
    switch (ErrorType) {
      case wixErrorTypeEnum.GeoLocationError:
        setTrackingConfigData(getTrackingConfiguration(wixOfacError));
        setTrackingAssetName(wixOfacError.trackingAssetName);
        trackPageLoad(wixOfacError);
        break;
      default:
        //Generic Error
        setTrackingConfigData(getTrackingConfiguration(pageAnalyticsData));
        setTrackingAssetName(pageAnalyticsData.trackingAssetName);
        trackPageLoad();
    }
  }

  // For Wix Initial Billing Page
  function trackInitialBillingPageLoad(planSelected: string): void {
    const VARIANT_LABEL = `plan:${planSelected}`;
    trackPageLoad(pageAnalyticsData);
    trackProductViewed(pageAnalyticsData, {
      label: BILLING_PAGE_LABEL,
      variant: VARIANT_LABEL,
      core_product_id: productKey,
    });
  }
  // For Wix Cancel Step 1 Page
  function trackCancelPageStep1PageLoad(): void {
    trackPageLoad(pageAnalyticsData);
  }

  // For Webs Shutdown Step 1 Page
  function trackWebsShutdownPageStep1PageLoad(): void {
    trackPageLoad(pageAnalyticsData);
  }

  // For Wix Site Selector Page
  function trackSiteSelectorPageLoad(planSelected: string): void {
    const VARIANT_LABEL = `plan:${planSelected ? planSelected : ''}`;
    trackPageLoad(pageAnalyticsData);
    trackProductViewed(pageAnalyticsData, {
      label: SITE_SELECTION_PAGE_LABEL,
      variant: VARIANT_LABEL,
      core_product_id: productKey,
    });
  }

  // Wix Plans Page - Business Tab
  function trackPlansBusinessPageLoad(planPageType: PageAnalyticsData): void {
    switch (planPageType) {
      case wixInitialPlansBusiness:
        setTrackingConfigData(getTrackingConfiguration(pageAnalyticsData));
        setTrackingAssetName(pageAnalyticsData.trackingAssetName);
        trackPageLoad(pageAnalyticsData);
        trackProductListViewed(
          pageAnalyticsData,
          BUSINESS_PLANS_LIST_COUNT,
          PLANS_PAGE_BUSINESS_VARIANT,
          {
            label: INITIAL_PLANS_PAGE_LABEL,
          },
          {
            core_product_id: productKey,
          },
          true
        );
        break;
      case wixUpgradePlansBusiness:
        setTrackingConfigData(
          getTrackingConfiguration(wixUpgradePlansBusiness)
        );
        setTrackingAssetName(wixUpgradePlansBusiness.trackingAssetName);
        trackPageLoad(wixUpgradePlansBusiness);
        trackProductListViewed(
          wixUpgradePlansBusiness,
          BUSINESS_PLANS_LIST_COUNT,
          PLANS_PAGE_BUSINESS_VARIANT,
          {
            label: SECOND_PLANS_PAGE_LABEL,
          },
          {
            core_product_id: productKey,
          }
        );
        break;
      case wixPlansBusiness:
      default:
        setTrackingConfigData(getTrackingConfiguration(pageAnalyticsData));
        setTrackingAssetName(pageAnalyticsData.trackingAssetName);
        trackPageLoad(pageAnalyticsData);
        trackProductListViewed(
          pageAnalyticsData,
          BUSINESS_PLANS_LIST_COUNT,
          PLANS_PAGE_BUSINESS_VARIANT,
          {
            label: PLANS_PAGE_LABEL,
          },
          {
            core_product_id: productKey,
          }
        );
    }
  }

  // Wix Plans Page - Websites Tab
  function trackPlansWebsitesPageLoad(planPageType: PageAnalyticsData): void {
    switch (planPageType) {
      case wixInitialPlansWebsites:
        setTrackingConfigData(
          getTrackingConfiguration(wixInitialPlansWebsites)
        );
        setTrackingAssetName(wixInitialPlansWebsites.trackingAssetName);
        trackPageLoad(wixInitialPlansWebsites);
        trackProductListViewed(
          wixInitialPlansWebsites,
          WEBSITES_PLANS_LIST_COUNT,
          PLANS_PAGE_WEBSITES_VARIANT,
          {
            label: INITIAL_PLANS_PAGE_LABEL,
          },
          {
            core_product_id: productKey,
          },
          true
        );
        break;
      case wixUpgradePlansWebsites:
        setTrackingConfigData(
          getTrackingConfiguration(wixUpgradePlansWebsites)
        );
        setTrackingAssetName(wixUpgradePlansWebsites.trackingAssetName);
        trackPageLoad(wixUpgradePlansWebsites);
        trackProductListViewed(
          wixUpgradePlansWebsites,
          WEBSITES_PLANS_LIST_COUNT,
          PLANS_PAGE_WEBSITES_VARIANT,
          {
            label: SECOND_PLANS_PAGE_LABEL,
          },
          {
            core_product_id: productKey,
          }
        );
        break;
      case wixPlansWebsites:
      default:
        setTrackingConfigData(getTrackingConfiguration(wixPlansWebsites));
        setTrackingAssetName(wixPlansWebsites.trackingAssetName);
        trackPageLoad(wixPlansWebsites);
        trackProductListViewed(
          wixPlansWebsites,
          WEBSITES_PLANS_LIST_COUNT,
          PLANS_PAGE_WEBSITES_VARIANT,
          {
            label: PLANS_PAGE_LABEL,
          },
          {
            core_product_id: productKey,
          }
        );
    }
  }
  // Wix Plans Page
  function trackPlansPageLoad(
    planPageType: PageAnalyticsData,
    locale?: string
  ): void {
    switch (planPageType) {
      case wixInitialPlans:
        setTrackingConfigData(getTrackingConfiguration(wixInitialPlans));
        setTrackingAssetName(wixInitialPlans.trackingAssetName);
        trackPageLoad(wixInitialPlans);
        trackProductListViewedV2(
          wixInitialPlans,
          PLANS_LIST_COUNT,
          PLANS_PAGE_VARIANT,
          {
            label: INITIAL_PLANS_PAGE_LABEL,
            list_id: TrackingListIds.INITIAL_UPGRADE_FLOW,
          },
          {
            core_product_id: productKey,
            list_section_id: LIST_SECTION_ID,
          },
          true
        );
        break;
      case wixChangePlans:
        setTrackingConfigData(getTrackingConfiguration(wixChangePlans));
        setTrackingAssetName(wixChangePlans.trackingAssetName);
        trackPageLoad(wixChangePlans);
        trackProductListViewedV2(
          wixChangePlans,
          PLANS_LIST_COUNT,
          PLANS_PAGE_VARIANT,
          {
            label: CHANGE_PLANS_PAGE_LABEL,
            list_id: TrackingListIds.CHANGE_PLAN_FLOW,
          },
          {
            core_product_id: productKey,
            list_section_id: LIST_SECTION_ID,
          },
          true
        );
        break;
      case wixUpgradePlans:
        setTrackingConfigData(getTrackingConfiguration(wixUpgradePlans));
        setTrackingAssetName(wixUpgradePlans.trackingAssetName);
        trackPageLoad(wixUpgradePlans);
        trackProductListViewedV2(
          wixUpgradePlans,
          PLANS_LIST_COUNT,
          PLANS_PAGE_VARIANT,
          {
            label: SECOND_PLANS_PAGE_LABEL,
            list_id: TrackingListIds.SECOND_UPGRADE_FLOW,
          },
          {
            core_product_id: productKey,
            list_section_id: LIST_SECTION_ID,
          }
        );
        break;
      case wixPlans:
      default:
        setTrackingConfigData(getTrackingConfiguration(wixPlans));
        setTrackingAssetName(wixPlans.trackingAssetName);
        trackPageLoad(wixPlans);
        trackProductListViewedV2(
          wixPlans,
          locale.toLocaleLowerCase() === 'en-us'
            ? PLANS_LIST_COUNT_WITH_FREE
            : PLANS_LIST_COUNT,
          locale.toLocaleLowerCase() === 'en-us'
            ? PLANS_PAGE_VARIANT_WITH_FREE
            : PLANS_PAGE_VARIANT,
          {
            label: PLANS_PAGE_LABEL,
            list_id: TrackingListIds.ANONYMOUS_FLOW,
          },
          {
            core_product_id: productKey,
            list_section_id: LIST_SECTION_ID,
          }
        );
    }
  }
  function trackPopUpClosedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    const newPage = page;
    newPage.category = TrackingPageSection.FLY_OUT;
    switch (page) {
      case wixInitialPlans:
        trackPopUpEvent(
          INITIAL_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLOSED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      case wixChangePlans:
        trackPopUpEvent(
          CHANGE_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLOSED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      case wixUpgradePlans:
        trackPopUpEvent(
          SECOND_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLOSED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      default:
        trackPopUpEvent(
          PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLOSED,
          Event?.toLowerCase(),
          newPage
        );
        break;
    }
  }
  function trackPopUpViewedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    const newPage = page;
    newPage.category = TrackingPageSection.FLY_OUT;
    switch (page) {
      case wixInitialPlans:
        trackPopUpEvent(
          INITIAL_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_VIEWED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      case wixChangePlans:
        trackPopUpEvent(
          CHANGE_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_VIEWED,
          Event?.toLowerCase(),
          newPage
        );
        break;

      case wixUpgradePlans:
        trackPopUpEvent(
          SECOND_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_VIEWED,
          Event?.toLowerCase(),
          newPage
        );
        break;

      default:
        trackPopUpEvent(
          PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_VIEWED,
          Event?.toLowerCase(),
          newPage
        );
        break;
    }
  }
  function trackModalClosedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    const newPage = page;
    newPage.category = TrackingPageSection.POP_UP;
    switch (page) {
      case wixChangePlans:
        trackPopUpEvent(
          MODAL_CONFIRMATION_POPUP_LABEL,
          TrackingAction.POPUP_CLOSED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      default:
        trackPopUpEvent(
          MODAL_CONFIRMATION_POPUP_LABEL,
          TrackingAction.POPUP_CLOSED,
          Event?.toLowerCase(),
          newPage
        );
        break;
    }
  }
  function trackModalViewedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    const newPage = page;
    newPage.category = TrackingPageSection.POP_UP;
    switch (page) {
      case wixChangePlans:
        trackPopUpEvent(
          MODAL_CONFIRMATION_POPUP_LABEL,
          TrackingAction.POPUP_VIEWED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      default:
        trackPopUpEvent(
          MODAL_CONFIRMATION_POPUP_LABEL,
          TrackingAction.POPUP_VIEWED,
          Event?.toLowerCase(),
          newPage
        );
        break;
    }
  }

  function trackModalClickedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    const newPage = page;
    newPage.category = TrackingPageSection.POP_UP;
    switch (page) {
      case wixChangePlans:
        trackPopUpEvent(
          MODAL_CONFIRMATION_POPUP_LABEL,
          TrackingAction.POPUP_CLICKED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      default:
        trackPopUpEvent(
          MODAL_CONFIRMATION_POPUP_LABEL,
          TrackingAction.POPUP_CLICKED,
          Event?.toLowerCase(),
          newPage
        );
        break;
    }
  }
  function trackPopUpClickedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    const newPage = page;
    newPage.category = TrackingPageSection.FLY_OUT;
    switch (page) {
      case wixInitialPlans:
        trackPopUpEvent(
          INITIAL_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLICKED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      case wixChangePlans:
        trackPopUpEvent(
          CHANGE_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLICKED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      case wixUpgradePlans:
        trackPopUpEvent(
          SECOND_PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLICKED,
          Event?.toLowerCase(),
          newPage
        );
        break;
      default:
        trackPopUpEvent(
          PLANS_PAGE_LABEL,
          TrackingAction.FLYOUT_CLICKED,
          Event?.toLowerCase(),
          newPage
        );
        break;
    }
  }

  function trackToggleClickedEvent(
    page: PageAnalyticsData,
    Event?: string
  ): void {
    switch (page) {
      case wixInitialPlans:
        trackToggleEvent(
          INITIAL_UPGRADE_FLOW,
          TrackingAction.BILLING_FREQUENTLY_TOGGLED,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
      case wixChangePlans:
        trackToggleEvent(
          CHANGE_PLAN_FLOW,
          TrackingAction.BILLING_FREQUENTLY_TOGGLED,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
      case wixUpgradePlans:
        trackToggleEvent(
          SECOND_UPGRADE_FLOW,
          TrackingAction.BILLING_FREQUENTLY_TOGGLED,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
      default:
        trackToggleEvent(
          ANONYMOUS_FLOW,
          TrackingAction.BILLING_FREQUENTLY_TOGGLED,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
    }
  }
  function trackPlanChosen(
    page: PageAnalyticsData,
    Event?: string,
    isUpgradeMonthlyToYearly?: boolean
  ): void {
    let action: TrackingAction = TrackingAction.PLAN_CHOSEN;
    if (isUpgradeMonthlyToYearly) {
      action = TrackingAction.PLAN_FREQUENCY_UPGRADED;
    }
    switch (page) {
      case wixInitialPlans:
        trackPlanChosenEvent(
          INITIAL_UPGRADE_FLOW,
          action,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
      case wixChangePlans:
        trackPlanChosenEvent(
          CHANGE_PLAN_FLOW,
          action,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
      case wixUpgradePlans:
        trackPlanChosenEvent(
          SECOND_UPGRADE_FLOW,
          action,
          Event?.toLowerCase(),
          page,
          { category: LIST_SECTION_ID }
        );
        break;
      default:
        trackPlanChosenEvent(
          ANONYMOUS_FLOW,
          action,
          Event?.toLowerCase(),
          page
        );
        break;
    }
  }

  function trackPlansProductAdded(
    page: PageAnalyticsData,
    planName?: string,
    quantity?: number
  ) {
    trackPlansAdded(page, {
      variant: planName,
      quantity: quantity,
      core_product_id: productKey,
    });
  }
  function fireStatsigImpression(
    experimentKey: experimentKeyEnum,
    variationKey: variationKeyEnum
  ) {
    initialize();
    (async () => {
      await waitTillAvailable(10000);
      fireImpression(experimentKey, variationKey, pageAnalyticsData);
    })();
  }
  return (
    <WixPageAnalyticsContext.Provider
      value={{
        trackProductLoad,
        trackPageLoad,
        trackErrorPageLoad,
        trackInitialBillingPageLoad,
        trackPlansWebsitesPageLoad,
        trackPlansBusinessPageLoad,
        trackPlansPageLoad,
        trackCancelPageStep1PageLoad,
        trackWebsShutdownPageStep1PageLoad,
        trackSiteSelectorPageLoad,
        trackPopUpViewedEvent,
        trackPopUpClosedEvent,
        trackPopUpClickedEvent,
        trackToggleClickedEvent,
        trackModalViewedEvent,
        trackModalClosedEvent,
        trackModalClickedEvent,
        trackPlanChosen,
        trackPlansProductAdded,
        fireStatsigImpression,
        trackingConfigData,
        trackingAssetName,
      }}
    >
      {children}
    </WixPageAnalyticsContext.Provider>
  );
};

export { WixPageAnalyticsContext, WixPageAnalyticsProvider };
